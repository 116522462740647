import React from "react";
import { Link } from "gatsby";

import './index.scss';

import iconLinked from '../../../images/icon-linkedin.svg';
import iconYoutube from '../../../images/icon-youtube.svg';
import iconFacebook from '../../../images/icon-facebook.svg';
import iconTwitter from '../../../images/icon-twitter.svg';
import iconInstagram from '../../../images/icon-instagram.svg';

export default ({pageContext}) => (
  <footer className="footer lpone" id="footer">
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-xs-12 col-md-6 left">
                        <div className={"footer-links"}><Link to='/metrix/'>METRIX</Link> | <Link to='/careers/'>CAREERS</Link> | <Link to='/legal/'>LEGAL</Link> | <Link to='/site-map/'>SITE MAP</Link></div>
        </div>
                    <div className="col-xs-12 col-md-6 right">
                        <div className="social-icons">
                            <div className="icon"><a href="https://mm-stats.com/ednpe" target="_blank" rel="noopener noreferrer"><img src={iconInstagram} alt=""/></a></div>
                            <div className="icon"><a href="https://mm-stats.com/jytor" target="_blank" rel="noopener noreferrer"><img src={iconLinked} alt=""/></a></div>
                            <div className="icon"><a href="https://mm-stats.com/jv4iq" target="_blank" rel="noopener noreferrer"><img src={iconYoutube} alt=""/></a></div>
                            <div className="icon"><a href="https://mm-stats.com/i4qef" target="_blank" rel="noopener noreferrer"><img src={iconFacebook} alt=""/></a></div>
                            <div className="icon"><a href="https://mm-stats.com/9popn" target="_blank" rel="noopener noreferrer"><img src={iconTwitter} alt=""/></a></div>
                        </div>
                        <div className={"footer-legal"}>&copy;2021 Merit Mile LLC. All rights reserved.</div>
                    </div>
      </div>
    </div>
  </footer>
)
